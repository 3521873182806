import { Container } from "@mui/material";
import { photos } from "../../content/photo";

export default function PhotographySection() {
  return (
    <Container sx={{ paddingY: 10, width: "100%" }}>
      {photos.map((photo) => (
        <img alt="ph-1" src={photo.img} width="100%" />
      ))}
    </Container>
  );
}

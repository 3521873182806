import { Typography } from "@mui/material";
import LandingSectionLayout from "./LandingSectionLayout";
import mainBackgroundImg from "../../content/images/main-background-image.jpeg";
import { translations } from "../../content/translations";
import { useLanguage } from "../../context";

export default function LandingSection() {
  const { language } = useLanguage();
  return (
    <LandingSectionLayout>
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={mainBackgroundImg}
        alt="increase priority"
      />
      <Typography variant="h3" color="white" align="center" letterSpacing={10}>
        {translations[language].title}
      </Typography>
    </LandingSectionLayout>
  );
}

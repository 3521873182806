import * as React from "react";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import landing1 from "../../content/images/landing-1.jpeg";

const LandingSectionLayoutRoot = styled("section")(({ theme }) => ({
  color: theme.palette.common.white,
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("xs")]: {
    height: "100vh",
    minHeight: 500,
    maxHeight: 1300,
  },
}));

const Background = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  zIndex: -2,
});

export default function ImageBackgroundStatic(
  props: React.HTMLAttributes<HTMLDivElement>
) {
  const { children } = props;

  return (
    <LandingSectionLayoutRoot>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "common.black",
            opacity: 0.5,
            zIndex: -1,
          }}
        />
        <Background
          sx={{
            backgroundImage: `url(${landing1})`,
            backgroundColor: "#7fc7d9", // Average color of the background image.
            backgroundPosition: "center",
            transition: "transform 0.5s ease-in-out",
          }}
        />
      </Container>
    </LandingSectionLayoutRoot>
  );
}

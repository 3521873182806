import { Link, Typography } from "@mui/material";
import contactImage from "../../content/images/contact-image.jpeg";
import { translations } from "../../content/translations";
import { useLanguage } from "../../context";
import { link } from "../../content";
import ImageBackgroundStatic from "./ImageBackgroundStatic";

export default function ContactSection() {
  const { language } = useLanguage();
  return (
    <ImageBackgroundStatic>
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={contactImage}
        alt="increase priority"
      />
      <Typography
        variant="h3"
        color="white"
        align="center"
        letterSpacing={10}
        mb={2}
      >
        {translations[language].title}
      </Typography>
      <Link
        color="#FFFFFF"
        letterSpacing={10}
        onClick={() => window.open(link, "_blank")}
        sx={{ cursor: "pointer", textAlign: "center" }}
      >
        {translations[language].clickHereContact}
      </Link>
    </ImageBackgroundStatic>
  );
}

import * as React from "react";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import landing1 from "../../content/images/landing-1.jpeg";
import landing2 from "../../content/images/landing-2.jpeg";
import landing3 from "../../content/images/landing-3.jpeg";
import landing4 from "../../content/images/landing-4.jpeg";
import landing5 from "../../content/images/landing-5.jpeg";
import landing6 from "../../content/images/landing-6.jpeg";
import landing7 from "../../content/images/landing-7.jpeg";
import landing8 from "../../content/images/landing-8.jpeg";

const LandingSectionLayoutRoot = styled("section")(({ theme }) => ({
  color: theme.palette.common.white,
  position: "relative",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("xs")]: {
    height: "100vh",
    minHeight: 500,
    maxHeight: 1300,
  },
}));

const Background = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  zIndex: -2,
});

export default function LandingSectionLayout(
  props: React.HTMLAttributes<HTMLDivElement>
) {
  const { children } = props;
  const [backgroundImage, setBackgroundImage] = React.useState<number>(0);

  const img1 = new Image();
  img1.src = landing1;

  const img2 = new Image();
  img2.src = landing2;

  const img3 = new Image();
  img3.src = landing3;

  const img4 = new Image();
  img4.src = landing4;

  const img5 = new Image();
  img5.src = landing5;

  const img6 = new Image();
  img6.src = landing6;

  const img7 = new Image();
  img7.src = landing7;

  const img8 = new Image();
  img8.src = landing8;

  const images = [
    landing1,
    landing2,
    landing3,
    landing4,
    landing5,
    landing6,
    landing7,
    landing8,
  ];

  React.useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImage((prev) => (prev + 1) % images.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <LandingSectionLayoutRoot>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: "common.black",
            opacity: 0.5,
            zIndex: -1,
          }}
        />
        <Background
          sx={{
            backgroundImage: `url(${images[backgroundImage]})`,
            backgroundColor: "#7fc7d9", // Average color of the background image.
            backgroundPosition: "center",
            transition: "background-image 1s ease-in-out",
          }}
        />
      </Container>
    </LandingSectionLayoutRoot>
  );
}

import { Link, Typography } from "@mui/material";
import contactImage from "../../content/images/contact-image.jpeg";
import { translations } from "../../content/translations";
import { useLanguage } from "../../context";
import ImageBackgroundStatic from "./ImageBackgroundStatic";

const link = "https://youtu.be/QVMJNUC6f2Q?si=X8HlR-92jMBMBxEs";

export default function MovieSection() {
  const { language } = useLanguage();
  return (
    <ImageBackgroundStatic>
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={contactImage}
        alt="increase priority"
      />
      <Typography
        variant="h3"
        color="white"
        align="center"
        mb={2}
        sx={{
          fontSize: { xs: "1rem", sm: "3rem", md: "4rem" },
          letterSpacing: { xs: 5, md: 10 },
        }}
      >
        {translations[language].movieName}
      </Typography>
      <Link
        color="#FFFFFF"
        letterSpacing={10}
        onClick={() => window.open(link, "_blank")}
        sx={{ cursor: "pointer", textAlign: "center" }}
      >
        {translations[language].clickHereMovie}
      </Link>
    </ImageBackgroundStatic>
  );
}

import beings1 from "./images/beings-1.png";
import beings2 from "./images/beings-2.png";
import beings3 from "./images/beings-3.png";
import beings4 from "./images/beings-4.png";
import beings5 from "./images/beings-5.png";
import beings6 from "./images/beings-6.png";
import beings7 from "./images/beings-7.png";
import beings8 from "./images/beings-8.png";

type Being = {
  title: string;
  material: string;
  method: string;
  size: string;
  realPrice: string;
  dollarPrice: string;
  img: string;
};

export const beings: Being[] = [
  {
    title: "Heart Shell I",
    material: "Argila grés",
    method: "Queima em forno a lenha",
    size: "110 x 70 x 40",
    realPrice: "25.000",
    dollarPrice: "4.550",
    img: beings1,
  },
  {
    title: "Heart Shell I",
    material: "Argila grés",
    method: "Queima em forno a lenha",
    size: "110 x 70 x 40",
    realPrice: "25.000",
    dollarPrice: "4.550",
    img: beings2,
  },
  {
    title: "Heart Shell I",
    material: "Argila grés",
    method: "Queima em forno a lenha",
    size: "110 x 70 x 40",
    realPrice: "25.000",
    dollarPrice: "4.550",
    img: beings3,
  },
  {
    title: "Heart Shell I",
    material: "Argila grés",
    method: "Queima em forno a lenha",
    size: "110 x 70 x 40",
    realPrice: "25.000",
    dollarPrice: "4.550",
    img: beings4,
  },
  {
    title: "Heart Shell I",
    material: "Argila grés",
    method: "Queima em forno a lenha",
    size: "110 x 70 x 40",
    realPrice: "25.000",
    dollarPrice: "4.550",
    img: beings5,
  },
  {
    title: "Heart Shell I",
    material: "Argila grés",
    method: "Queima em forno a lenha",
    size: "110 x 70 x 40",
    realPrice: "25.000",
    dollarPrice: "4.550",
    img: beings6,
  },
  {
    title: "Heart Shell I",
    material: "Argila grés",
    method: "Queima em forno a lenha",
    size: "110 x 70 x 40",
    realPrice: "25.000",
    dollarPrice: "4.550",
    img: beings7,
  },
  {
    title: "Heart Shell I",
    material: "Argila grés",
    method: "Queima em forno a lenha",
    size: "110 x 70 x 40",
    realPrice: "25.000",
    dollarPrice: "4.550",
    img: beings8,
  },
];

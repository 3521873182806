import { createTheme } from "@mui/material/styles";
import { green, red } from "@mui/material/colors";
import "@fontsource/playfair-display";

const theme = createTheme({
  palette: {
    primary: {
      light: "#E6D0CA",
      main: "#2E394B",
      dark: "#2E394B",
    },
    secondary: {
      light: "#FFFFFF",
      main: "#EDDBC5",
      dark: "#FFFFFF",
    },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e",
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      light: green[50],
      main: green[500],
      dark: green[700],
    },
    text: {
      primary: "#2E394B",
      secondary: "#2E394B",
    },
  },
  typography: {
    fontFamily: "'Roboto Condensed', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 600, // Roboto Condensed
  },
});

export default theme;

import { Box, Container, Typography } from "@mui/material";
import { translations } from "../../content/translations";
import { useLanguage } from "../../context";

export default function BiographySection() {
  const { language } = useLanguage();
  return (
    <Container sx={{ paddingY: 10 }}>
      <Box sx={{ mb: 5, letterSpacing: 5 }}>
        <Typography variant="h4">{translations[language].bio}</Typography>
      </Box>
      <Box sx={{ letterSpacing: 1 }}>
        <Typography marginBottom={2}>
          {translations[language].biography.p1}
        </Typography>
        <Typography marginBottom={2}>
          {translations[language].biography.p2}
        </Typography>
        <Typography marginBottom={2}>
          {translations[language].biography.p3}
        </Typography>
        <Typography marginBottom={2}>
          {translations[language].biography.p4}
        </Typography>
        <Typography marginBottom={2}>
          {translations[language].biography.p5}
        </Typography>
        <Typography marginBottom={2}>
          {translations[language].biography.p6}
        </Typography>
        <Typography>{translations[language].biography.p7}</Typography>
      </Box>
    </Container>
  );
}

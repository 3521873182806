import seaMandals1 from "./images/sea-mandals-1.png";
import seaMandals2 from "./images/sea-mandals-2.png";

type Being = {
  title: string;
  material: string;
  method: string;
  size: string;
  realPrice: string;
  dollarPrice: string;
  img: string;
};

export const seaMandals: Being[] = [
  {
    title: "Heart Shell I",
    material: "Argila grés",
    method: "Queima em forno a lenha",
    size: "110 x 70 x 40",
    realPrice: "25.000",
    dollarPrice: "4.550",
    img: seaMandals1,
  },
  {
    title: "Heart Shell I",
    material: "Argila grés",
    method: "Queima em forno a lenha",
    size: "110 x 70 x 40",
    realPrice: "25.000",
    dollarPrice: "4.550",
    img: seaMandals2,
  },
];

import { Box, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Language, LanguageContext } from "../../context";
import { useState } from "react";
import { translations } from "../../content/translations";

interface Props {
  children: React.ReactNode;
}

export default function SiteLayout({ children }: Props) {
  const { pathname } = useLocation();
  const [language, setLanguage] = useState<Language>(Language.BR);

  return (
    <LanguageContext.Provider value={{ language }}>
      <Box maxWidth="100vw">
        <Tabs
          sx={{
            position: "absolute",
            right: { xs: 0, md: 0 },
            zIndex: 99,
            color: "white",
            overflowX: "auto",
            width: { xs: "100vw", md: "62vw" },
          }}
          value={pathname}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Select
            labelId="language-select"
            id="language-select"
            value={language}
            sx={{ color: "white", display: { xs: "block", md: "none" } }}
          >
            <MenuItem value="br" onClick={() => setLanguage(Language.BR)}>
              PT
            </MenuItem>
            <MenuItem value="en" onClick={() => setLanguage(Language.EN)}>
              EN
            </MenuItem>
          </Select>
          <Tab
            sx={{
              color:
                pathname === "/" ||
                pathname === "/contact" ||
                pathname === "/movie"
                  ? "white !important"
                  : "primary.main",
              textTransform: "none",
              letterSpacing: { md: 2 },
              marginBottom: "2px solid white",
            }}
            label={translations[language].home}
            value="/"
            to="/"
            component={Link}
          />
          <Tab
            sx={{
              color:
                pathname === "/" ||
                pathname === "/contact" ||
                pathname === "/movie"
                  ? "white !important"
                  : "primary.main",
              textTransform: "none",
              letterSpacing: { md: 2 },
              marginBottom: "2px solid white",
            }}
            label={translations[language].bio}
            value="/bio"
            to="/bio"
            component={Link}
          />
          <Tab
            sx={{
              color:
                pathname === "/" ||
                pathname === "/contact" ||
                pathname === "/movie"
                  ? "white !important"
                  : "primary.main",
              textTransform: "none",
              letterSpacing: { md: 2 },
              marginBottom: "2px solid white",
            }}
            label={translations[language].statement}
            value="/declaration"
            to="/declaration"
            component={Link}
          />
          <Tab
            sx={{
              color:
                pathname === "/" ||
                pathname === "/contact" ||
                pathname === "/movie"
                  ? "white !important"
                  : "primary.main",
              textTransform: "none",
              letterSpacing: { md: 2 },
              marginBottom: "2px solid white",
            }}
            label={translations[language].clay}
            value="/clay"
            to="/clay"
            component={Link}
          />
          <Tab
            sx={{
              color:
                pathname === "/" ||
                pathname === "/contact" ||
                pathname === "/movie"
                  ? "white !important"
                  : "primary.main",
              textTransform: "none",
              letterSpacing: { md: 2 },
              marginBottom: "2px solid white",
            }}
            label={translations[language].bronze}
            value="/bronze"
            to="/bronze"
            component={Link}
          />
          <Tab
            sx={{
              color:
                pathname === "/" ||
                pathname === "/contact" ||
                pathname === "/movie"
                  ? "white !important"
                  : "primary.main",
              textTransform: "none",
              letterSpacing: { md: 2 },
              marginBottom: "2px solid white",
            }}
            label={translations[language].photopraphy}
            value="/photopraphy"
            to="/photopraphy"
            component={Link}
          />
          <Tab
            sx={{
              color:
                pathname === "/" ||
                pathname === "/contact" ||
                pathname === "/movie"
                  ? "white !important"
                  : "primary.main",
              textTransform: "none",
              letterSpacing: { md: 2 },
              marginBottom: "2px solid white",
            }}
            label={translations[language].movie}
            value="/movie"
            to="/movie"
            component={Link}
          />
          <Tab
            sx={{
              color:
                pathname === "/" ||
                pathname === "/contact" ||
                pathname === "/movie"
                  ? "white !important"
                  : "primary.main",
              textTransform: "none",
              letterSpacing: { md: 2 },
              marginBottom: "2px solid white",
            }}
            label={translations[language].contact}
            value="/contact"
            to="/contact"
            component={Link}
          />
          <Select
            labelId="language-select"
            id="language-select"
            value={language}
            sx={{ color: "white", display: { xs: "none", md: "block" } }}
          >
            <MenuItem value="br" onClick={() => setLanguage(Language.BR)}>
              PT
            </MenuItem>
            <MenuItem value="en" onClick={() => setLanguage(Language.EN)}>
              EN
            </MenuItem>
          </Select>
        </Tabs>
        {children}
      </Box>
    </LanguageContext.Provider>
  );
}

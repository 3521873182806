import React, { useContext } from "react";

export enum Language {
  BR = "br",
  EN = "en",
}

type Context = {
  language: Language;
};

export const LanguageContext = React.createContext<Context | null>(null);

export function useLanguage() {
  const context = useContext<Context | null>(LanguageContext);
  if (context === null) {
    throw new Error("Language check must be used in inside LanguageProvider");
  }

  return {
    language: context.language,
  };
}

import { Container } from "@mui/material";
import { metamorphics } from "../../content/metamorphic";
import { board } from "../../content/board";
import { install } from "../../content/install";
import { dancers } from "../../content/dancers";
import { beings } from "../../content/beings";
import { seaMandals } from "../../content/mandals";

export default function ClaySection() {
  return (
    <Container sx={{ paddingY: 10, width: "100%" }}>
      <img alt="metamorphic-1" src={metamorphics.img} width="100%" />
      <img alt="metamorphic-2" src={metamorphics.img2} width="100%" />
      <img alt="board-1" src={board.img} width="100%" />
      <img alt="install-1" src={install.img} width="100%" />
      <img alt="dancers-1" src={dancers.img} width="100%" />

      {beings.map((being) => (
        <img alt="beings-1" src={being.img} width="100%" />
      ))}

      {seaMandals.map((mandal) => (
        <img alt="mandals-1" src={mandal.img} width="100%" />
      ))}
    </Container>
  );
}

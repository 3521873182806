import install1 from "./images/install-1.png";

type Install = {
  material: string;
  method: string;
  size: string;
  realPrice: string;
  dollarPrice: string;
  img: string;
};

export const install: Install = {
  material: "Argila grés",
  method: "Queima a lenha",
  size: "Aprox. 45 x 30 cm",
  realPrice: "R$ 1.500 cada peça",
  dollarPrice: "US$ 275 each piece",
  img: install1,
};

import { Container } from "@mui/material";
import { copper } from "../../content/copper";

export default function BronzeSection() {
  return (
    <Container sx={{ paddingY: 10, width: "100%" }}>
      <img alt="copper-1" src={copper.img} width="100%" />
    </Container>
  );
}

export const translations = {
  en: {
    title: "MIU sculptures",
    home: "Home",
    bio: "Biography",
    statement: "Artist statement",
    clay: "Clays",
    bronze: "Bronze",
    photopraphy: "Photographs",
    movie: "Movie",
    contact: "Contact",
    movieName: "SAKTI SENI (A Magia da Arte)",
    clickHereMovie: "Click here to watch",
    clickHereContact: "Clique aqui e entre em contato",
    biography: {
      p1: "Souls come to Earth in search of healing.",
      p2: "Mine began in childhood when I would spend my summers on the beach, playing all day long, between the sand and the sea.",
      p3: "Born in Minas Gerais, I am a daughter of the earth, but throughout life I discovered that my soul is also made of sand. My first encounter with clay was fluid and liberating; I felt a sense of completeness, something that embraced me entirely. Years later, still carrying this feeling, I graduated in Fine Arts from the State University of Minas Gerais - Escola de Artes Guignard, in 2000. The desire to share this magical involvement with art led me to the path of Waldorf pedagogy, where I trained as a teacher.",
      p4: "As life unfolded, after a long pause, I returned to sculptural ceramics at Colorado Mountain College in the United States. This reunion led me to seek artistic experiences in different parts of the world, but it was in Florianópolis, Santa Catarina, that I planted deep roots and where my two children were born.",
      p5: "Ten years ago, I built my studio and a wood-fired kiln. The firing process has always fascinated me, even before I understood its full complexity. Fire, with its slow and burning dance, marks the pieces in a ritual of patience and surrender. Transforming clay into ceramics is an act of eternalization, a rite of passage that is both symbolic and a molecular transformation of the earth.",
      p6: "My sculptural practice on the beaches, modeling the sand, has accompanied me throughout the years. The moments of creation, solitary and meditative, are a dialogue with the time of making. The forms are born from the work of an entire day, being completed with the rise of the tide, when the work culminates with the seawater invading all the paths my hands have carved. In this ephemeral process, at the end of the day, the sculpture melts into the sea, evoking a feeling of impermanence and hope.",
      p7: "In artistic creation, I find a belief in love, beauty, and the power of nature-an inexhaustible source of inspiration.",
    },
    artistStatement: {
      p1: "Between weight and lightness, the form is visceral beauty.",
      p2: "It captures the viewer's gaze,",
      p3: "A delight of its own internal essence.",
      p4: "There is innocence in contrast to sexuality.",
      p5: "It dives deep.",
      p6: "Into the intimacy of the darkest places,",
      p7: "Into the mysteries of constant changes.",
      p8: "Impermanence of self in the world.",
      p9: "In the encounter with light, contemplation.",
      p10: "The intense process",
      p11: "Of sculpting sand and clay.",
      p12: "Hard work, an empty mind,",
      p13: "Reviving the essence of life.",
      p14: "Touch, feel, experience,",
      p15: "Turning inside out.",
      p16: "Movement, dance, and hands",
      p17: "Make the body flow through the gesture.",
      p18: "Connecting with the heart",
      p19: "Finding a refuge from great storms,",
      p20: "Where solitude and peace reign,",
      p21: "A hidden place in the past",
      p22: "Further back than the ancestors can recall.",
      p23: "Immersing in its own world,",
      p24: "Where it flies with the birds,",
      p25: "Floats and traces high in the sky.",
      p26: "Sometimes like water, melting the earth beneath its feet,",
      p27: "Sometimes like the sun, burning its exposed skin.",
      p28: "A path of poetry, back to the maternal womb.",
      p29: "Completeness of being,",
      p30: "The stars speak,",
      p31: "The moon soothes,",
      p32: "Soul.",
      p33: "I am Nature...",
      p34: "Where I find myself,",
      p35: "A bond pulsing with emotion",
      p36: "Strong enough to make love reborn.",
    },
    clays: {
      claySculptures: "Esculturas de Cerâmicas - Jardim de Vênus",
      beings: "Beings",
      metamorphic: "Metamorphic",
    },
  },
  br: {
    title: "MIU sculptures",
    home: "Home",
    bio: "Biografia",
    statement: "Declaração",
    clay: "Cerâmicas",
    bronze: "Bronze",
    photopraphy: "Fotografias",
    movie: "Filme",
    contact: "Contato",
    movieName: "SAKTI SENI (A Magia da Arte)",
    clickHereMovie: "Clique aqui para assistir",
    clickHereContact: "Clique aqui e entre em contato",
    biography: {
      p1: "As almas vêm à terra em busca de cura.",
      p2: "A minha inicia-se já na infância quando passava os verões na praia brincando o dia todo, entre a areia e o mar.",
      p3: "Nascida em Minas Gerais, sou filha da terra, mas no percorrer da vida descobri que de alma sou também feita de areia. Meu primeiro encontro com a argila foi fluido e libertador; senti a completude, algo que me envolveu por inteiro. Anos depois, ainda carregando esse sentimento, concluí minha formação em Artes Plásticas na Universidade Estadual de Minas Gerais - Escola de Artes Guignard, no ano de 2000. O desejo de compartilhar esse envolvimento mágico com a arte me levou ao caminho da pedagogia Waldorf, onde me formei professora.",
      p4: "No percorrer da vida, após uma longa pausa, retornei à cerâmica escultórica no Colorado Mountain College, nos Estados Unidos. Esse reencontro me levou a buscar experiências artísticas em diferentes lugares do mundo, mas foi em Florianópolis/SC que criei raízes profundas e onde meus dois filhos nasceram.",
      p5: "Há dez anos, construí meu ateliê e um forno a lenha, o processo de queima sempre me fascinou, mesmo antes de conhecê-lo em sua multiplicidade. O fogo, com sua dança lenta e ardente, marca as peças em um ritual de paciência e entrega. Transformar barro em cerâmica é um ato eternizador, um rito de passagem tanto simbólico, quanto molecular da terra.",
      p6: "Já a prática escultórica nas praias, modelando a areia, me acompanha ao longo dos anos. Os momentos de criação, solitários e meditativos, são um diálogo com o tempo do fazer. As formas nascem no trabalho de um dia inteiro, sendo finalizadas com a subida da maré, quando o trabalho se completa com a água do mar invadindo todos os caminhos que minhas mãos cavaram. Nesse processo efêmero, no fim do dia a escultura se dissolve no mar, em um sentimento de impermanência e esperança.",
      p7: "No fazer artístico encontro a crença no amor, na beleza e na potência da natureza, fonte inesgotável de inspiração.",
    },
    artistStatement: {
      p1: "Entre o peso e a leveza, a forma é beleza visceral.",
      p2: "Captura o olhar do espectador,",
      p3: "Deleite de sua própria essência interna.",
      p4: "Há uma inocência em contraste com a sexualidade.",
      p5: "Mergulha fundo.",
      p6: "Na intimidade dos lugares mais escuros,",
      p7: "Nos mistérios das constantes mudanças.",
      p8: "Impermanência do ser no mundo.",
      p9: "No encontro da luz, a contemplação.",
      p10: "O intenso processo",
      p11: "De esculpir areia e argila.",
      p12: "O trabalho duro, a mente vazia,",
      p13: "Ressurge o que há de vida.",
      p14: "Tocar, sentir, experienciar,",
      p15: "Virar de dentro para fora.",
      p16: "Movimento, dança e mãos",
      p17: "Faz o corpo fluir através do gesto.",
      p18: "Conectando com o coração",
      p19: "Encontra um refúgio das grandes tempestades,",
      p20: "Onde a solitude e a paz reinam,",
      p21: "Lugar oculto no passado",
      p22: "Mais distante do que os ancestrais conseguem recordar.",
      p23: "Imergindo em seu próprio mundo,",
      p24: "Onde voa com os pássaros,",
      p25: "Flutua e risca alto no céu.",
      p26: "Às vezes como água, derretendo a terra sob seus pés,",
      p27: "Às vezes como sol, ardendo sua pele exposta.",
      p28: "Caminho de poesia, de volta ao útero materno.",
      p29: "Completude do ser,",
      p30: "As estrelas falam,",
      p31: "A lua acalma,",
      p32: "Alma.",
      p33: "Eu sou Natureza...",
      p34: "Onde me encontro,",
      p35: "Vínculo que pulsa emoção",
      p36: "Forte o bastante para fazer o amor renascer.",
    },
    clays: {
      claySculptures: "Esculturas de Cerâmicas - Jardim de Vênus",
      beings: "Seres",
      metamorphic: "Metafóricas",
    },
  },
};

import board1 from "./images/board-1.png";

type Board = {
  material: string;
  method: string;
  size: string;
  realPrice: string;
  dollarPrice: string;
  img: string;
};

export const board: Board = {
  material: "Argila grés",
  method: "Queima a lenha",
  size: "Aprox. 45 x 30 cm",
  realPrice: "R$ 1.500 cada peça",
  dollarPrice: "US$ 275 each piece",
  img: board1,
};

import { Box, Container, Typography } from "@mui/material";
import { translations } from "../../content/translations";
import { useLanguage } from "../../context";

export default function DeclarationSection() {
  const { language } = useLanguage();
  return (
    <Container sx={{ paddingY: 10 }}>
      <Box sx={{ mb: 5, letterSpacing: 5 }}>
        <Typography variant="h4">{translations[language].statement}</Typography>
      </Box>
      <Box sx={{ letterSpacing: 1 }}>
        <Typography>{translations[language].artistStatement.p1}</Typography>
        <Typography>{translations[language].artistStatement.p2}</Typography>
        <Typography>{translations[language].artistStatement.p3}</Typography>
        <Typography marginBottom={2}>
          {translations[language].artistStatement.p4}
        </Typography>
        <Typography>{translations[language].artistStatement.p5}</Typography>
        <Typography> {translations[language].artistStatement.p6}</Typography>
        <Typography> {translations[language].artistStatement.p7}</Typography>
        <Typography marginBottom={2}>
          {translations[language].artistStatement.p8}
        </Typography>
        <Typography marginBottom={2}>
          {translations[language].artistStatement.p9}
        </Typography>
        <Typography>{translations[language].artistStatement.p10}</Typography>
        <Typography>{translations[language].artistStatement.p11}</Typography>
        <Typography>{translations[language].artistStatement.p12}</Typography>
        <Typography marginBottom={2}>
          {translations[language].artistStatement.p13}
        </Typography>
        <Typography>{translations[language].artistStatement.p14}</Typography>
        <Typography>{translations[language].artistStatement.p15}</Typography>
        <Typography>{translations[language].artistStatement.p16}</Typography>
        <Typography marginBottom={2}>
          {translations[language].artistStatement.p17}
        </Typography>
        <Typography>{translations[language].artistStatement.p18}</Typography>
        <Typography>{translations[language].artistStatement.p19}</Typography>
        <Typography>{translations[language].artistStatement.p20}</Typography>
        <Typography>{translations[language].artistStatement.p21}</Typography>
        <Typography marginBottom={2}>
          {translations[language].artistStatement.p22}
        </Typography>
        <Typography>{translations[language].artistStatement.p23}</Typography>
        <Typography>{translations[language].artistStatement.p24}</Typography>
        <Typography>{translations[language].artistStatement.p25}</Typography>
        <Typography>{translations[language].artistStatement.p26}</Typography>
        <Typography marginBottom={2}>
          {translations[language].artistStatement.p27}
        </Typography>
        <Typography>{translations[language].artistStatement.p28}</Typography>
        <Typography>{translations[language].artistStatement.p29}</Typography>
        <Typography>{translations[language].artistStatement.p30}</Typography>
        <Typography>{translations[language].artistStatement.p31}</Typography>
        <Typography marginBottom={2}>
          {" "}
          {translations[language].artistStatement.p32}
        </Typography>
        <Typography>{translations[language].artistStatement.p33}</Typography>
        <Typography>{translations[language].artistStatement.p34}</Typography>
        <Typography>{translations[language].artistStatement.p35}</Typography>
        <Typography>{translations[language].artistStatement.p36}</Typography>
      </Box>
    </Container>
  );
}
